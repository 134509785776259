
.admin {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    display: flex;
    text-align: center;
    align-content: center;
}

.admin input{
    margin-bottom: 40px;
}

.admin-login-title{
    font-size: 50px;
    margin-bottom: 30px;
}